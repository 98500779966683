import React, { useEffect, useState } from 'react';
import { 
  ConnectWallet, 
  Web3Button,
  useContract,
  useContractRead,
  useAddress,
  useTokenBalance,
  ThirdwebNftMedia,
  darkTheme
} from "@thirdweb-dev/react";
import NFTCard from "./components/NFTCard";
import "./styles/Home.css";
import { BigNumber, ethers } from "ethers";

interface NftBalance {
  tokenId: string;
  metadata: {
    id: string;
    uri: string;
    name?: string;
    description?: string;
    image?: string;
    [key: string]: any;
  };
  tokenUri: string;
}

const App: React.FC = () => {
  const address = useAddress();
  const [ownedTokens, setOwnedTokens] = useState<NftBalance[]>([]);
  const [stakedTokens, setStakedTokens] = useState<BigNumber[]>([]);
  const [claimableRewards, setClaimableRewards] = useState<BigNumber>();
  const [isLoading, setIsLoading] = useState(false);

  // define contract addresses
  const distortionsContractAddress = "0x0A337Be2EA71E3aeA9C82D45b036aC6a6123B6D0";
  const stakingContractAddress = "0x51697170F78136c8d143B0013Cf5B229aDe70757";

  // initialize contracts for interaction
  const { contract: distortionsContract } = useContract(
    distortionsContractAddress,
    "nft-drop"
  );
  const { contract: stakingContract } = useContract(stakingContractAddress);

  useEffect(() => {
    if (address) {
      console.log("Address changed, refreshing token balances and staked tokens...");
      refreshTokenBalances();
      loadStakedTokens();
      loadClaimableRewards();
    }
  }, [address]);

  const refreshTokenBalances = async () => {
    try {
      if (!address) return;
      setIsLoading(true);
      console.log("Fetching owned tokens...");
      const response = await fetch(
        `https://glacier-api.avax.network/v1/chains/43114/addresses/${address}/balances:listErc721?pageSize=10&contractAddress=${distortionsContractAddress}`
      );
      const data = await response.json();
      console.log("Owned tokens fetched:", data);
      if (data && data.erc721TokenBalances) {
        const tokenBalances: NftBalance[] = await Promise.all(
          data.erc721TokenBalances.map(async (item: any) => {
            const tokenId = item.tokenId;
            let tokenUri = item.tokenUri;
            if (tokenUri.startsWith('ipfs://')) {
              tokenUri = tokenUri.replace('ipfs://', 'https://ipfs.io/ipfs/');
            }
            let metadata: any = {};
            try {
              const metadataResponse = await fetch(tokenUri);
              metadata = await metadataResponse.json();
              if (metadata.image && metadata.image.startsWith('ipfs://')) {
                metadata.image = metadata.image.replace('ipfs://', 'https://ipfs.io/ipfs/');
              }
            } catch (err) {
              console.error(`Failed to fetch metadata for token ${tokenId}:`, err);
            }
            return {
              tokenId,
              metadata: {
                ...metadata,
                id: tokenId,
                uri: tokenUri,
              },
              tokenUri: item.tokenUri,
            };
          })
        );
        setOwnedTokens(tokenBalances);
      } else {
        setOwnedTokens([]);
      }
    } catch (error) {
      console.error('Failed to fetch owned NFTs:', error);
      setOwnedTokens([]);
    } finally {
      setIsLoading(false);
    }
  };

  const loadStakedTokens = async () => {
    try {
      if (!stakingContract || !address) return;
      setIsLoading(true);
      console.log("Fetching staked tokens...");
      const stakeInfo = await stakingContract.call("getStakeInfo", [address]);
      console.log("Staked tokens fetched:", stakeInfo);
      setStakedTokens(stakeInfo[0]);
    } catch (error) {
      console.error("Failed to load staked tokens:", error);
      setStakedTokens([]);
    } finally {
      setIsLoading(false);
    }
  };

  const loadClaimableRewards = async () => {
    try {
      if (!stakingContract || !address) return;
      setIsLoading(true);
      console.log("Fetching claimable rewards...");
      const stakeInfo = await stakingContract.call("getStakeInfo", [address]);
      console.log("Claimable rewards fetched:", stakeInfo[1]);
      setClaimableRewards(stakeInfo[1]);
    } catch (error) {
      console.error("Error loading claimable rewards:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleStake = async (id: string) => {
    try {
      if (!address || !stakingContract) return;
      setIsLoading(true);
      console.log("Staking token with ID:", id);
      const isApproved = await distortionsContract?.call("isApprovedForAll", [address, stakingContractAddress]);
      if (!isApproved) {
        console.log("Setting approval for staking contract...");
        await distortionsContract?.call("setApprovalForAll", [stakingContractAddress, true]);
      }
      const transaction = await stakingContract.call("stake", [[id]]);
      if (transaction) {
        console.log("Token staked successfully, refreshing data...");
        await refreshTokenBalances();
        await loadStakedTokens();
        await loadClaimableRewards();
      }
    } catch (error) {
      console.error('Stake error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUnstake = async (id: string) => {
    try {
      if (!stakingContract) return;
      setIsLoading(true);
      console.log("Unstaking token with ID:", id);
      const transaction = await stakingContract.call("withdraw", [[id]]);
      if (transaction) {
        console.log("Token unstaked successfully, refreshing data...");
        await refreshTokenBalances();
        await loadStakedTokens();
        await loadClaimableRewards();
      }
    } catch (error) {
      console.error('Unstake error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const isWalletConnected = !!address;
  const hasTokenStaked = stakedTokens.length > 0;
  const { data: auraBalance } = useTokenBalance(stakingContract, address);

  return (
    <main className="main bg1">
      <div className="colbox">
        <h1 className="fa head">Enter The City</h1>
        {!isWalletConnected && (
          <ConnectWallet 
          className="ccbutton" 
          style={{ 
            fontFamily: 'Doctor Glitch',
            fontWeight: 'normal',
            fontStyle: 'oblique',
            color: 'white'
          }} 
          theme={darkTheme({ fontFamily: "Hacked, sans-serif", colors: { modalBg: "#002020", accentText: "cyan" } })}
          />
        )}
      </div>
      <div className="colbox">
      {isWalletConnected && (
        <div className="colbox bg2">
          <h1 className="fa">Stake</h1>
            <div className="rowboxGAP widthboost">
              <div className="colbox">
                <h3 className="fb">
                  <b className="text">
                    {!claimableRewards
                      ? "Loading..."
                      : Math.round(Number(ethers.utils.formatUnits(claimableRewards, 18)))}
                  </b>
                  <br />
                  Aura Pending
                </h3>
                <Web3Button
                    className="ccbutton"
                    action={(contract) => contract.call("claimRewards")}
                    contractAddress={stakingContractAddress}
                  >
                    <p className="fb">Claim Rewards</p>
                  </Web3Button>
              </div>
            </div>
          <div className="rowboxGAP">
            <div className="rmar">
              <div className="colbox">
                <h2 className="fa">Your Distortions</h2>
                <div className="rowbox">
                  {ownedTokens?.map((nft) => (
                    <div key={nft.tokenId} className="nftbox">
                      <ThirdwebNftMedia metadata={nft.metadata} className="nftmedia" />
                      <h2 className="fa text br sidepadding">"{nft.metadata.name}"</h2>
                      <h4 className="fb text br sidepadding">Token {nft.tokenId}</h4>
                      <Web3Button
                        contractAddress={stakingContractAddress}
                        action={() => handleStake(nft.tokenId)}
                        className="stakebutton br sidemarginbm"
                      >
                        <p className="fb">Stake for Aura</p>
                      </Web3Button>
                    </div>
                  ))}
                </div>
                {hasTokenStaked && (
                  <>
                    <h3 className="fa">Staked:</h3>
                    <div>
                      {stakedTokens &&
                        stakedTokens.map((stakedToken) => (
                          <NFTCard
                            tokenId={stakedToken.toNumber()}
                            key={stakedToken.toString()}
                            onUnstake={handleUnstake}
                          />
                        ))}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {isWalletConnected && (
        <div className="colbox bg3">
          <div className="rowbox widthboost">
            <p className="text">
              <b>{auraBalance?.displayValue}</b><b className="fb">$AURA</b>
            </p>
            <button className="buta">
              <h1 className="fa">Explore The City</h1>
              <br />
              <h3 className="fb">Coming Soon...</h3>
            </button>
            <p className="text">
              <b></b><b className="fb">$AVAX</b>
            </p>
          </div>
          <h2 className="fb">Gluttony's Indulgence<br />500 $AURA<br />0.015 $AVAX</h2>
        </div>
      )}
      </div>
    </main>
  );
};

export default App;
